import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import routes from 'routes';

export default () => {
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState(null);
    const {role} = useSelector(state => state.users.currentUser);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.searchTerm.endpoint}`);
                setSearchTerm(result.data.data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        fetchData();
    }, []);

    return {isLoading, searchTerm: searchTerm};
}
