import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader, Divider} from '@material-ui/core';
import routes from '../../../routes';
import MaterialUITable from '../../Custom/MaterialUITable';
import rules from '../../../rbac-rules';
import permissions from '../../../permissions';
import {check} from '../../RBAC/AccessControl';

function Brands(props) {

    const { brands } = props;

    const history = useHistory();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.detail]);

    return (
       <Card>
           <CardHeader
               title='Brand Campaigns Associated with Agency'
               titleTypographyProps={
                   {variant: 'h5'}
               }
           />
           <Divider />
           <CardContent>
               <MaterialUITable
                   data={brands}
                   columns={[
                       {title: 'Brand Name', field: 'brandName'},
                       {title: 'Pharma Company', field: 'pharmaCompany'},
                       {
                           title: 'Search Strategist',
                           field: 'searchStrategists',
                           customFilterAndSearch: (term, rowData) => {
                               if (rowData.searchStrategists !== undefined) {
                                   return rowData.searchStrategists.some(
                                       (strategist) => strategist.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())
                                   )
                               }
                           },
                           render: rowData => {
                               if (rowData.searchStrategists !== undefined && rowData.searchStrategists !== null) {
                                   return rowData.searchStrategists.map(
                                       strategist => strategist.name.trim()
                                   ).join(', ');
                               }
                           }
                       },
                   ]}
                   {...(canEdit && {onRowClick:
                           ((event, rowData) => {
                               history.push(`${routes.brands.path}/${rowData.id}`);
                           })

                   })}
               />
           </CardContent>
       </Card>
    );
}

Brands.propTypes = {
    brands: PropTypes.array,
};

export default Brands;
