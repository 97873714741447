import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import CreateAgencyForm from 'components/Agencies/Create';
import Main from 'components/Layout/Main';

import {successToast, errorToast} from 'actions';
import routes from 'routes';
import AccessControl from "../../../components/RBAC/AccessControl";
import permissions from "../../../permissions";
import Forbidden from "../../../components/Forbidden";

function CreateAgencyContainer(props) {

    const history = useHistory();

    const saveAgency = async (agency) => {
        try {
            await axios.post(routes.agencies.endpoint, agency);
            props.successToast(`${agency.name} successfully created!`);
            history.push(routes.agencies.path);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.agencies.create]} noAccess={() => <Forbidden/>}>
                <CreateAgencyForm saveAgency={saveAgency}/>
            </AccessControl>
        </Main>
    );
}


const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg))
});

export default connect(null, mapDispatchToProps)(CreateAgencyContainer);
