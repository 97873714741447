export const setReport = report => ({
    type: 'SET_REPORT',
    report
});

export const setBrands = brands => ({
    type: 'SET_BRANDS',
    brands
});

/* Toasts */
export const successToast = (msg) => ({
    type: 'SUCCESS_TOAST',
    msg
});

export const errorToast = (msg) => ({
    type: 'ERROR_TOAST',
    msg
});

/* Agencies */
export const setAgencies = agencies => ({
    type: 'SET_AGENCIES',
    agencies
});

export const addAgency = agency => ({
    type: 'ADD_AGENCY',
    agency
});

/* Current User */
export const setCurrentUser = user => ({
    type: 'SET_CURRENT_USER',
    user
});

export const setUnreadViolationsCount = violationsCount => ({
    type: 'SET_UNREAD_VIOLATIONS_COUNT',
    violationsCount
});
