import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Card, CardHeader, CardContent, Divider, Grid, makeStyles } from '@material-ui/core';

import SearchStrategists from './SearchStrategists';
import PharmaCompany from './PharmaCompany';
import SearchTerms from './SearchTerms';
import EmailAlerts from './EmailAlerts';
import DomainUrls from './DomainUrls';
import BrandName from './BrandName';
import AdAgency from './AdAgency';

import searchTerms from './SearchTerms/searchTerms';
import routes from 'routes';
import roles from 'roles';
import useUsers from 'api/useUsers';
import BingEnabled from './BingEnalbed';
import GoogleEnabled from './GoogleEnabled';
import WeeklyReportEnabled from './WeeklyReportEnabled';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
        color: theme.palette.primary.dark
    }
}));

function BrandDetailsComponent(props) {

    const location = useLocation();
    const classes = useStyles();
    const { brand, updateBrand, saveBrand, agencies } = props;


    let params = new URLSearchParams();
    params.append('agencyId', brand.agencyId);
    params.append('role', roles.SEARCH_STRATEGIST.name);
    const { users: strategists } = useUsers(params, [brand.agencyId], true);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>

                <Grid item xs={12} md={6}>
                    <BrandName brand={brand} classes={classes} updateBrand={updateBrand} saveBrand={saveBrand} />
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader title='Brand Details' />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <PharmaCompany classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <SearchStrategists strategists={strategists} classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <AdAgency classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} agencies={agencies} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <BingEnabled classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <GoogleEnabled classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <WeeklyReportEnabled classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                </Grid>



                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <EmailAlerts classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <DomainUrls classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {props.searchTerms !== null &&
                    <>

                        <Grid item xs={12}>
                            <SearchTerms classes={classes} type={searchTerms.searchTerms} saveTerms={props.saveTerms}
                                searchTerms={props.searchTerms.searchTerms} count={props.searchTerms.searchTermsCount}
                                route={`${location.pathname}/${routes.searchTerms.searchTerms.path}`} />
                        </Grid>

                        <Grid item xs={12}>
                            <SearchTerms classes={classes} type={searchTerms.genericTerms} saveTerms={props.saveTerms}
                                searchTerms={props.searchTerms.genericTerms} count={props.searchTerms.genericTermsCount}
                                route={`${location.pathname}/${routes.searchTerms.genericTerms.path}`} />
                        </Grid>

                        <Grid item xs={12}>
                            <SearchTerms classes={classes} type={searchTerms.onLabelTerms} saveTerms={props.saveTerms}
                                searchTerms={props.searchTerms.onLabelTerms} count={props.searchTerms.onLabelTermsCount}
                                route={`${location.pathname}/${routes.searchTerms.onLabelTerms.path}`} />
                        </Grid>

                        <Grid item xs={12}>
                            <SearchTerms classes={classes} type={searchTerms.offLabelTerms} saveTerms={props.saveTerms}
                                searchTerms={props.searchTerms.offLabelTerms} count={props.searchTerms.offLabelTermsCount}
                                route={`${location.pathname}/${routes.searchTerms.offLabelTerms.path}`} />

                        </Grid>
                    </>
                }

            </Grid>
        </div>
    );
}

BrandDetailsComponent.propTypes = {
    brand: PropTypes.object,
    searchTerms: PropTypes.object,
    saveBrand: PropTypes.func,
    saveTerms: PropTypes.func,
    updateBrand: PropTypes.func,
    agencies: PropTypes.array
};

export default BrandDetailsComponent;
