import React, {useState} from 'react';

import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import {KeyboardBackspace as KeyboardBackspaceIcon} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    button: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
}));

function CreateAgencyForm(props) {

    const [agencyName, setAgencyName] = useState('');
    const [agencyActive, setAgencyActive] = useState(true);

    const [error, setError] = useState(false);
    const history = useHistory();
    const classes = useStyles();

    const handleSubmit = (event) => {
        event.preventDefault();
        props.saveAgency({name: agencyName, active: agencyActive});
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Card>
                        <CardHeader
                            avatar={
                                <IconButton aria-label='Go Back' edge='start' onClick={() => history.goBack()}>
                                    <KeyboardBackspaceIcon/>
                                </IconButton>
                            }
                            title='Add Agency'
                            titleTypographyProps={
                                {variant: 'h5'}
                            }
                            subheaderTypographyProps={
                                {variant: 'body1'}
                            }
                            subheader='Complete the following fields and select create to add a new agency to AdComplyRx!'
                        />
                        <Divider/>
                        <CardContent>
                            <form onSubmit={handleSubmit}>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={error}>
                                            <TextField
                                                id='agency-name'
                                                label='Agency Name'
                                                value={agencyName}
                                                onChange={event => setAgencyName(event.target.value)}
                                                size='small'
                                                required
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={error}>
                                            <InputLabel id='agency-status-label'>Status</InputLabel>
                                            <Select
                                                labelId='agency-status-label'
                                                id='agency-status'
                                                value={agencyActive}
                                                onChange={event => setAgencyActive(event.target.value === 'true')}
                                                input={<Input/>}
                                                renderValue={(value => value ? 'Active' : 'Inactive')}
                                            >
                                                <MenuItem value='true'>
                                                    Active
                                                </MenuItem>

                                                <MenuItem value='false'>
                                                    Inactive
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid container item justify="flex-end" xs={12}>
                                        <Button
                                            variant="text"
                                            className={classes.button}
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            type='submit'
                                            className={classes.button}
                                            disabled={!agencyName}
                                        >
                                            Create
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

CreateAgencyForm.propTypes = {};

export default CreateAgencyForm;
