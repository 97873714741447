import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import routes from 'routes';
import permissions from 'permissions';
import rules from 'rbac-rules';
import {check} from 'components/RBAC/AccessControl';
import {setUnreadViolationsCount} from "../actions";

export default () => {
    const [adCount, setAdCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const {role} = useSelector(state => state.users.currentUser);
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.head(routes.violations.endpoint);
                setAdCount(result.headers["x-unread-count"]);
                dispatch(setUnreadViolationsCount(result.headers["x-unread-count"]))
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        if (check(rules, role, [permissions.violations.list])) {
            setIsLoading(true);
            fetchData();
        }
    }, []);

    return {isLoading, violationsCount: adCount};
}
