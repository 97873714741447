import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {IconButton, makeStyles, MenuItem, Select, Typography} from '@material-ui/core';
import permissions from '../../../permissions';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import AccessControl, {check} from '../../RBAC/AccessControl';
import rules from '../../../rbac-rules';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
    }
}));

function AdAgency(props) {

    const classes = useStyles();
    const { brand, updateBrand, saveBrand, agencies } = props;
    const [isEditing, setEditing] = useState(false);
    const [adAgency, setAdAgency] = useState(brand.agency !== null ? brand.agency.id : '');
    const [adAgencyName, setAdAgencyName] = useState(brand.agency !== null ? brand.agency.name : '');
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.update.full]);

    const submit = ({ value }) => {
        if ((brand.agency != null && brand.agency.id !== value) || brand.agency === null) {
            const updatedBrand = { ...brand, agencyId: value, searchStrategists: [] };
            updateBrand(updatedBrand);
            saveBrand({ brand: updatedBrand, msg: 'Agency updated!' });
        }
        setEditing(false);
    };

    const handleChange = (event) => {
        const selectedAgency = agencies.filter(agency => agency.agencyId === event.target.value);
        setAdAgency(event.target.value);
        setAdAgencyName(selectedAgency[0].agencyName)
        submit({ value: event.target.value });
    }

    const handleMenuItemClick = (event) => {
        setEditing(false);
    }

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Ad Agency
            </Typography>

            {isEditing ? (
                <Select
                    style={{ width: '100%' }}
                    labelId='ad-agency-label'
                    id='ad-agency'
                    value={adAgency}
                    onChange={handleChange}
                >
                    {agencies.length === 0 &&
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    }
                    {agencies.map((agency) => (
                        <MenuItem key={agency.agencyId} value={agency.agencyId} onClick={handleMenuItemClick}>
                            {agency.agencyName}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                    <Typography {...(canEdit && {onClick: () => setEditing(true)})}>
                        {adAgencyName}
                        <AccessControl allowedPermissions={[permissions.brands.update.searchStrategist]}>
                            <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft} onClick={() => setEditing(true)}>
                                <CreateSharpIcon fontSize='small' color='secondary' />
                            </IconButton>
                        </AccessControl>
                    </Typography>
            )}
        </>
    );
}

AdAgency.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func,
    agencies: PropTypes.array
};

export default AdAgency;
