import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        textAlign: 'center'
    },
    h1: {
        fontSize: '2rem',
        lineHeight: '1.2',
    }
}));

function Forbidden() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component='h1' gutterBottom className={classes.h1}>403</Typography>
                    <Typography component='h2'>Forbidden.</Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default Forbidden;
