import React from 'react';
import PropTypes from 'prop-types';
import {InputLabel, MenuItem, Select} from '@material-ui/core';

function ReportSelect(props) {

    const {report, setReport} = props;

    return (
        <>
            <InputLabel id='report-select-label'>Select Report</InputLabel>
            <Select
                labelId='report-select-label'
                id='report-select'
                value={report}
                onChange={event => setReport(event.target.value)}
                size='small'
            >
                <MenuItem value='brandcompliance'>Brand Non-Compliance
                    Report</MenuItem>
            </Select>
        </>
    );
}

ReportSelect.propTypes = {
    report: PropTypes.string ,
    setReport: PropTypes.func,
};

export default ReportSelect;
