import React from 'react';
import PropTypes from 'prop-types';

import {
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import {format} from 'date-fns';
import clsx from 'clsx';

import ViolationComponent from 'components/Violations/Violation';
import Link from "@material-ui/core/Link";
import routes from "../../../routes";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    media: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1.5)
    },
    image: {
        width: theme.spacing(30)
    },
    toolbarGutters: {
        paddingRight: theme.spacing(0),
        paddingLeft: theme.spacing(0)
    },
    th: {
        fontWeight: 'bold',
    },
    borderBottomNone: {
        borderBottom: 'none'
    },
    title: {
        fontWeight: "bolder",
    },
    link: {
        color: theme.palette.primary.dark,
    }

}));

function NonComplianceReportComponent(props) {

    const classes = useStyles();

    const {report} = props;
    const {reportSummary, reportDetail} = report;

    const brandList = reportSummary.brandSummaryList.map(option => option.brandName).join(', ');
    return (
        <Grid container spacing={3} className={clsx(classes.root, 'nonComplianceReport')}>
            <Grid item xs={12} md={10} lg={8} xl={6}>
                <Paper>
                    <div className={classes.media}>
                        <img className={classes.image} src='/images/weblogo_transparent.png' alt='AdComplyRx logo'/>
                    </div>

                    <CardHeader
                        action={
                            <IconButton aria-label='settings' onClick={() => window.print()} className='noPrint'>
                                <PrintIcon/>
                            </IconButton>
                        }
                        title={'Brand Non-Compliance Report: ' + brandList}
                        titleTypographyProps={
                            {variant: 'h4'}
                        }
                        classes={classes.title}
                        subheader={
                            <Link href={`${routes.reports.path}${props.queryString}`} className={clsx(classes.link, 'noPrint')}>Edit Report Parameters</Link>
                        }
                        subheaderTypographyProps={
                            {variant: "body1"}
                        }
                    />

                    <CardContent>
                        <Table size='small'>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.th} component='th' scope='row'>Ads Captured From
                                        Date</TableCell>
                                    <TableCell
                                        align="right">{reportSummary.startDate ? format(new Date(reportSummary.startDate), "MMM d, yyyy") : null}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.th} component='th' scope='row'>Ads Captured To
                                        Date</TableCell>
                                    <TableCell
                                        align="right">{reportSummary.endDate ? format(new Date(reportSummary.endDate), "MMM d, yyyy") : null}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.th} component='th' scope='row'>Search Terms
                                        Monitored</TableCell>
                                    <TableCell align="right">{reportSummary.searchTermCount}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={clsx(classes.th, classes.borderBottomNone)} component='th'
                                               scope='row'>Total Ads Captured</TableCell>
                                    <TableCell align="right"
                                               className={clsx(classes.borderBottomNone)}>{reportSummary.totalAdCaptureCount}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>

                    {reportSummary.brandSummaryList.length > 0 &&
                    <CardHeader title='Brand Summary'/>
                    }

                    {reportSummary.brandSummaryList.map(brand => (
                        <CardContent key={brand.brandId}>
                            <Table size='small'>
                                <TableBody>
                                    <TableRow variant='head'>
                                        <TableCell className={classes.th} component='th' scope='row'
                                                   colSpan={2}>{brand.brandName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component='th' scope='row'>Ads Passed Compliance
                                            Inspection</TableCell>
                                        <TableCell align="right">{brand.adComplianceCount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component='th' scope='row'>Ads Failed Chemical Drug Name
                                            Rule</TableCell>
                                        <TableCell
                                            align="right">{brand.adViolationGenericNameNotIncludedCount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component='th' scope='row'>Ads Failed Brand Claim without Safety
                                            Info Rule</TableCell>
                                        <TableCell
                                            align="right">{brand.adViolationSafetyInfoNotIncludedCount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component='th' scope='row'>Ads Failed Off Label Promotion
                                            Rule</TableCell>
                                        <TableCell align="right">{brand.adViolationOffLabelClaimCount}</TableCell>
                                    </TableRow>
                                    <TableRow variant='footer'>
                                        <TableCell className={clsx(classes.th, classes.borderBottomNone)} component='th'
                                                   scope='row'>Total Ads Captured</TableCell>
                                        <TableCell align="right"
                                                   className={clsx(classes.th, classes.borderBottomNone)}>{brand.totalAdCaptureCount}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    ))}

                    {reportDetail.violatingAds.map(ad => (
                        <div key={ad.adId}>
                            <Divider/>
                            <ViolationComponent adViolation={ad} hidePrintButton/>
                        </div>
                    ))}
                </Paper>
            </Grid>
        </Grid>
    );
}

NonComplianceReportComponent.propTypes = {
    report: PropTypes.object
};

export default NonComplianceReportComponent;
