import React from 'react';

import {Card, CardContent, CardHeader, Divider, Grid} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';

import palette from 'theme/palette';
import routes from 'routes';
import {format} from 'date-fns';
import MaterialUITable from '../../Custom/MaterialUITable';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    }
}));

export default function ViolationsTableComponent(props) {

    const {violations} = props;
    const {violationsCount} = props;
    const classes = useStyles();
    let history = useHistory();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card variant='outlined'>
                        <CardHeader title={routes.violations.pageTitle}
                                    subheader={violationsCount + ' unread violations'}/>
                        <Divider/>
                        <CardContent className={classes.cardContent}>

                            <MaterialUITable
                                data={violations}
                                columns={[
                                    {title: 'Brand Name', field: 'brandName'},
                                    {title: 'Ad Title', field: 'adHeadline'},
                                    {title: 'Advertiser URL', field: 'adDisplayUrl'},
                                    {
                                        title: 'Search Term', field: 'searchTerms',
                                        render: rowData => {
                                            return rowData.searchTerms.join(', ');
                                        }
                                    },
                                    {title: 'Search Provider', field: 'adProvider'},
                                    {
                                        title: 'Date/Time',
                                        field: 'adCaptureDate',
                                        render: rowData => {
                                            return format(new Date(rowData.adCaptureDate), "MMM d, yyyy 'at' h:mmaaaa");
                                        },
                                        customFilterAndSearch: (term, rowData) =>
                                            rowData.adCaptureDate === null ? false : (format(new Date(rowData.adCaptureDate), "MMM d, yyyy 'at' h:mmaaaa").toLowerCase()).includes(term.toLowerCase())
                                    },
                                ]}
                                rowStyle={rowData => !rowData.viewedByUser ? {color: palette.primary.dark, fontWeight: '900'} : null}
                                {...{onRowClick: (event, rowData) => {
                                        const violationId = rowData.adId;
                                        history.push(`${routes.violations.path}/${violationId}`);
                                }}}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
