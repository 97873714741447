import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Chip, TextField} from '@material-ui/core';
import PropTypes from 'prop-types';

function BrandSelect(props) {

    const {brand, setBrand} = props;

    const handleBrandChange = (event, newValue, reason) => {
        setBrand(newValue.filter(option => option.id !== -1))
    };

    const handleBrandDelete = (event, item) => {
        setBrand(brand.filter(option => option.id !== item.id));
    };

    return (
        <>
            <Autocomplete
                multiple
                id='brand-select'
                options={props.brands}
                getOptionLabel={(option) => option.brandName}
                autoHighlight
                getOptionSelected={
                    (option, brand) => {
                        return option.id === brand.id
                    }
                }
                filterSelectedOptions
                value={brand.length === 0 ? [{ id: -1, brandName: 'All', value: '' }] : brand}
                onChange={
                    (event, newValue, reason) => handleBrandChange(event, newValue, reason)
                }
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                            <Chip
                                {...(option.id !== -1 && getTagProps({index}))}
                                key={option.id}
                                label={option.brandName}
                                size='small'
                                {...(option.id !== -1 && {onDelete: ((event) => handleBrandDelete(event, option))})}
                            />
                        )
                    )}
                renderInput={params => (
                    <TextField {...params} label='Select Brand'/>
                )}
            />
        </>
    );
}

BrandSelect.propTypes = {
    brand: PropTypes.array,
    setBrand: PropTypes.func,
    setLastSelectedBrand: PropTypes.func,
    setLastDeletedBrand: PropTypes.func
};

export default BrandSelect;