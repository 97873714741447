import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { Divider, Grid, Typography, Paper, Button, makeStyles, TextField,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AccessControl from "../../RBAC/AccessControl";
import permissions from "../../../permissions";

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'none'
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    divider: {
        width: 1,
        height: 28,
        margin: '4px 10px',
    },
    paperForm: {
        padding: theme.spacing(2)
    },
    addButton: {
        marginTop: '4px',
        marginLeft: '10px'
    }
}));

function SearchTerms(props) {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [term, setTerm] = useState('');
    const [terms, setTerms] = useState('');
    const { route, type, count } = props;

    const addTerm = (event) => {
        event.preventDefault();
        if (term !== '') {
            props.saveTerms({terms: [{type: type.type, term}], msg: `${term} successfully added to ${type.name}`});
            setTerm('');
        }
    };

    const addTerms = (event) => {
        event.preventDefault();
        if (terms !== '') {
            props.saveTerms({ type: type.type,
                terms: terms.split('\n').filter(term => term !== '').map(value => ({term: value, type: type.type})),
                msg: `Terms successfully added to ${type.name}`});
            setTerms('');
            setOpen(false);
        }
    };

    return (
        <Paper className={clsx(classes.paperForm, props.classes !== undefined && props.classes.paperForm)}>

            <Grid container spacing={3} direction='row'
                  justify='space-between'
                  alignItems='center'>
                <Grid item xs={12} sm={12} md={5} xl={7}>
                    <div>
                        <Typography className={clsx(props.classes !== undefined && props.classes.title)} gutterBottom variant='body2'>
                            {type.title.replace('$terms', count).replace('$s', count === 1 ? '' : 's')}
                        </Typography>
                        <Typography>{type.description}</Typography>
                    </div>
                </Grid>
                <Grid container item xs={12} sm={12} md={7} xl={5} justify='flex-end'>
                    <Paper className={classes.paperRoot}>
                        <AccessControl allowedPermissions={[permissions.brands.update.basic]}>
                            <div>
                                <form onSubmit={addTerm}>
                                    <TextField
                                        className={classes.input}
                                        label={`Add ${type.singularName}`}
                                        inputProps={{ 'aria-label': `Add ${type.singularName}` }}
                                        value={term}
                                        onChange={event => setTerm(event.target.value)}
                                        variant='outlined'
                                        size='small'
                                    />
                                    <Button size='small' color='secondary' variant='contained' type='submit' className={classes.addButton} >
                                        Add
                                    </Button>
                                </form>
                            </div>
                            <Divider className={classes.divider} orientation='vertical' />
                            <Button variant='contained' size='small' color='secondary' onClick={() => setOpen(true)}>
                                Bulk Add {type.name === 'Chemical Names' ? 'Names' : 'Terms'}
                            </Button>
                        </AccessControl>
                        {props.route !== undefined &&
                            <>
                                <AccessControl allowedPermissions={[permissions.brands.update.basic]}>
                                    <Divider className={classes.divider} orientation='vertical' />
                                </AccessControl>
                                <Link style={{textDecoration: 'none'}} to={route}>
                                    <Button variant='contained' size='small' color='secondary'>
                                        View {type.name === 'Chemical Names' ? 'Names' : 'Terms'}
                                    </Button>
                                </Link>
                            </>
                        }
                        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='form-dialog-title'>
                            <DialogTitle id='form-dialog-title'>Bulk Add {type.name}</DialogTitle>
                            <form onSubmit={addTerms}>
                                <DialogContent>
                                    <DialogContentText>
                                        Add one term per line and then select the add button to add terms
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        multiline
                                        margin='dense'
                                        rowsMax={10}
                                        id='name'
                                        label={type.name}
                                        type='text'
                                        fullWidth
                                        value={terms}
                                        onChange={event => setTerms(event.target.value)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpen(false)} color='primary'>
                                        Cancel
                                    </Button>
                                    <Button type='submit' color='secondary' variant='contained'>
                                        Add
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
}

SearchTerms.propTypes = {
    classes: PropTypes.object,
    route: PropTypes.string,
    type: PropTypes.object.isRequired,
    saveTerms: PropTypes.func,
    searchTerms: PropTypes.array,
    count: PropTypes.number
};

export default SearchTerms;