import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Chip, IconButton, makeStyles, TextField, Typography} from '@material-ui/core';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useSelector} from "react-redux";
import AccessControl, {check} from "../../RBAC/AccessControl";
import rules from "../../../rbac-rules";
import permissions from "../../../permissions";

const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
    }
}));

function EmailAlerts(props) {

    const [isEditing, setEditing] = useState(false);
    const [readyToSave, setReadyToSave] = useState(false);
    const [firstTimeClick, setFirstTimeClick] = useState(true);
    const [lastCreatedBySpace, setLastCreatedBySpace] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const {brand, updateBrand, saveBrand} = props;
    const [emailAlerts, setEmailAlerts] = useState(brand.emailAlerts === null ? [] : brand.emailAlerts);
    const classes = useStyles();
    const {currentUser} = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.update.basic]);

    const submit = () => {
        setEditing(false);
        setReadyToSave(false);
        setFirstTimeClick(true);
        if (emailAlerts !== brand.emailAlerts) {
            const updatedBrand = {...brand, emailAlerts};
            saveBrand({brand: updatedBrand, msg: 'Email alerts updated!'});
            updateBrand(updatedBrand);
        }
    };

    const onBlur = () => {
        submit();
    };

    const handleOnKeyDown = (event) => {
        if (event.key === ' ' && newEmail !== '' && newEmail !== ' ') {
            let emails = emailAlerts;
            if (!emails.includes(newEmail)) {
                emails.push(newEmail);
            }
            handleBrandEmailAlertsChange(event, emails, '');
            setNewEmail('');
            setReadyToSave(true);
            setLastCreatedBySpace(true);
        } else if (event.key === 'Enter' && readyToSave) {
            submit();
        } else if (event.key === 'Enter' && firstTimeClick) {
            submit();
        } else if (event.key === 'Backspace' && lastCreatedBySpace && newEmail === '') {
            let emails = emailAlerts;
            emails.pop();
            handleBrandEmailAlertsChange(event, emails, '');
        }
    };

    const handleBrandEmailAlertsChange = (event, newValue, reason) => {
        setEmailAlerts(newValue.filter(value => value.trim() !== ''));
        setLastCreatedBySpace(false);
    };

    const handleBrandEmailAlertsDelete = (event, email) => {
        setReadyToSave(true);
        setEmailAlerts(emailAlerts.filter(option => option !== email));
    };

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Email Alerts
            </Typography>

            {isEditing ? (
                <Autocomplete
                    multiple
                    freeSolo
                    id='brand-email-alerts'
                    options={[]}
                    onKeyDown={handleOnKeyDown}
                    value={emailAlerts}
                    onChange={(event, newValue, reason) =>
                        handleBrandEmailAlertsChange(event, newValue, reason)}
                    onInputChange={(event, value, reason) => {
                        setFirstTimeClick(false);
                        setNewEmail(value.trim());
                        setReadyToSave(value.trim() === '');
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                                <Chip
                                    {...getTagProps({index})}
                                    label={option}
                                    onDelete={event => handleBrandEmailAlertsDelete(event, option)}
                                    size='small'
                                />
                            )
                        )}
                    renderInput={params => (
                        <>
                            <TextField {...params} inputProps={{...params.inputProps, value: newEmail}} onBlur={onBlur}
                                       size='small' autoFocus/>
                        </>)}
                />
            ) : (
                <div
                    className={classes.chipRoot}
                    {...(canEdit &&
                        {onClick: () => setEditing(true)}
                    )}
                >
                    {emailAlerts.map((email, idx) => (
                        <Chip key={idx} label={email} size='small'/>
                    ))}
                    <AccessControl allowedPermissions={[permissions.brands.update.basic]}>
                        <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft}
                                    onClick={() => setEditing(true)}>
                            <CreateSharpIcon fontSize='small' color='secondary'/>
                        </IconButton>
                    </AccessControl>
                </div>
            )}
        </>
    );
}

EmailAlerts.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func
};

export default EmailAlerts;
