import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
    ClickAwayListener,
    Tooltip,
    makeStyles,
    Button, FormHelperText
} from '@material-ui/core';
import { KeyboardBackspace as KeyboardBackspaceIcon, Help as HelpIcon } from '@material-ui/icons';

import routes from 'routes';
import roles from 'roles';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    button: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    customWidthTooltip: {
        maxWidth: '500',
    },
}));

function UserEditComponent(props) {

    const { user } = props;

    const { currentUser } = useSelector(state => state.users);
    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [agency, setAgency] = useState(user.agency.id);
    const [role, setRole] = useState(user.role);
    const [phoneNumber, setPhoneNumber] = useState(user.phone);
    const [tempPassword, setTempPassword] = useState(user.tempPassword);
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(user.active);

    const {error} = props;

    const history = useHistory();
    const classes = useStyles();

    const handleSubmit = (event) => {
        event.preventDefault();
        props.updateUser({ name, email, agencyId: agency, role, phone: phoneNumber, tempPassword, active });
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Card>
                        <CardHeader
                            avatar={
                                <IconButton aria-label='Go Back' edge='start' onClick={() => history.goBack()}>
                                    <KeyboardBackspaceIcon/>
                                </IconButton>
                            }
                            title='Edit User'
                            titleTypographyProps={{ variant: 'h5' }}
                            subheaderTypographyProps={{ variant: 'body1' }}
                            subheader='Edit any field and select update to modify the user.'
                        />
                        <Divider/>
                        <CardContent>
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={false}>
                                            <TextField
                                                required
                                                id='user-name'
                                                label='Name'
                                                value={name}
                                                size='small'
                                                onChange={event => setName(event.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                disabled
                                                error={error.field === 'email'}
                                                required
                                                id='user-email'
                                                label='Email Address'
                                                value={email}
                                                size='small'
                                                onChange={event => setEmail(event.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={false}>
                                            <TextField
                                                id='user-phone-number'
                                                label='Phone Number'
                                                value={phoneNumber}
                                                size='small'
                                                onChange={event => setPhoneNumber(event.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={false}>
                                            <InputLabel id='agency-select-label'>Agency</InputLabel>
                                            <Select
                                                labelId='agency-select-label'
                                                id='agency-select'
                                                value={agency}
                                                onChange={event => setAgency(event.target.value)}
                                                size='small'
                                                required
                                            >
                                                {props.agencies.map(agency => (
                                                    <MenuItem key={agency.agencyId} value={agency.agencyId}>{agency.agencyName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={false}>
                                            <InputLabel id='role-select-label'>Role</InputLabel>
                                            <Select
                                                labelId='role-select-label'
                                                id='role-select'
                                                value={role}
                                                onChange={event => setRole(event.target.value)}
                                                size='small'
                                                required
                                            >
                                                {Object.keys(roles).filter((role) => roles[role].level <= roles[currentUser.role].level).map(role => (
                                                    <MenuItem key={roles[role].name} value={roles[role].name}>{roles[role].displayName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={false}>
                                            <InputLabel htmlFor='standard-adornment-password' required>Temporary Password</InputLabel>
                                            <Input
                                                required
                                                id='standard-adornment-password'
                                                type='text'
                                                size='small'
                                                value={tempPassword}
                                                onChange={event => setTempPassword(event.target.value)}
                                                endAdornment={
                                                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                                                        <Tooltip
                                                            placement='left-start'
                                                            classes={{ tooltip: classes.customWidthTooltip }}
                                                            onClose={() => setOpen(false)}
                                                            open={open}
                                                            arrow
                                                            title={`Password must be 8 characters and include
                                                             one upper, one lower, and one number.`}
                                                        >
                                                            <InputAdornment position='end'>
                                                                <IconButton onClick={() => setOpen(true)}>
                                                                    <HelpIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        </Tooltip>
                                                    </ClickAwayListener>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={false}>
                                            <InputLabel id='user-status-label'>Status</InputLabel>
                                            <Select
                                                labelId='user-status-label'
                                                id='user-status'
                                                size='small'
                                                value={active}
                                                onChange={event => setActive(event.target.value)}
                                            >
                                                <MenuItem value={true}>Active</MenuItem>
                                                <MenuItem value={false}>Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item justify='flex-end' xs={12}>
                                        <Button variant='text' className={classes.button} onClick={() => history.push(routes.users.path)}>
                                            Cancel
                                        </Button>
                                        <Button variant='contained' color='secondary' type='submit' className={classes.button}
                                                disabled={!name || !email || !agency || !role || !tempPassword ||
                                                    (name === user.name && email === user.email && agency === user.agency.id
                                                    && phoneNumber === user.phone && role === user.role
                                                        && tempPassword === user.tempPassword && active === user.active)
                                                }>
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

UserEditComponent.propTypes = {
    agencies: PropTypes.array,
    roles: PropTypes.object,
    updateUser: PropTypes.func
};

export default UserEditComponent;
