import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import routes from 'routes';
import permissions from 'permissions';
import rules from 'rbac-rules';
import {check} from 'components/RBAC/AccessControl';

export default (brandId) => {
    const [isLoading, setIsLoading] = useState(true);
    const [brand, setBrand] = useState(null);
    const {role} = useSelector(state => state.users.currentUser);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.brands.endpoint}/${brandId}`);
                setBrand(result.data.data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        if (check(rules, role, [permissions.brands.list, permissions.brands.detail, permissions.brands.update.basic, permissions.brands.update.full])) {
            setIsLoading(true);
            fetchData();
        }
    }, []);

    return {isLoading, brand};
}
