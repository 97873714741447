import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import AgenciesTableComponent from 'components/Agencies/List';
import Main from 'components/Layout/Main';
import useAgencies from 'api/useAgencies';

import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';

function AgenciesContainer() {

    const { isLoading, agencies } = useAgencies();

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.agencies.list]} noAccess={() => <Forbidden/>}>
                {isLoading ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <AgenciesTableComponent agencies={agencies}/>
                )}
            </AccessControl>
        </Main>
    );
}

export default AgenciesContainer;
