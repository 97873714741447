import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import routes from 'routes';
import permissions from 'permissions';
import rules from 'rbac-rules';
import {check} from 'components/RBAC/AccessControl';

export default (params, dependencies, condition) => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {role} = useSelector(state => state.users.currentUser);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(routes.users.endpoint, {params});
                setUsers(result.data.data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        if (check(rules, role, [permissions.users.list, permissions.users.self]) && condition) {
            setIsLoading(true);
            fetchData();
        }
    }, dependencies);

    return {isLoading, users};
}
