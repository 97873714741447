import React from 'react';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';

import LinearProgress from '@material-ui/core/LinearProgress';
import CreateBrandForm from 'components/Brands/Create';
import Main from 'components/Layout/Main';

import {successToast, errorToast} from 'actions';
import routes from 'routes';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useAgencies from 'api/useAgencies';

function CreateBrandContainer(props) {

    let history = useHistory();

    // Load all the agencies
    const { isLoading: isLoadingAgencies, agencies } = useAgencies();
    
    const saveBrand = async (brand) => {
        try {
            const result = await axios.post(routes.brands.endpoint, brand);
            props.successToast(`${brand.brandName} successfully created!`);
            history.push(`${routes.brands.path}/${result.data.data.id}`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.brands.create]} noAccess={() => <Forbidden/>}>
                {isLoadingAgencies ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <CreateBrandForm saveBrand={saveBrand} agencies={agencies}/>
                )}
            </AccessControl>
        </Main>
    );
}

const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg))
});

export default connect(null, mapDispatchToProps)(CreateBrandContainer);
