import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {Typography, TextField, makeStyles, IconButton, Grid, FormHelperText} from '@material-ui/core';
import { KeyboardBackspace as KeyboardBackspaceIcon, CreateSharp as CreateSharpIcon } from '@material-ui/icons';

import AccessControl, {check} from "../../RBAC/AccessControl";
import rules from "../../../rbac-rules";
import permissions from "../../../permissions";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    input: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    }
}));

function BrandName(props) {

    const [isEditing, setEditing] = useState(false);
    const { brand, updateBrand, saveBrand } = props;
    const [brandName, setBrandName] = useState(brand.brandName);
    const classes = useStyles();
    const history = useHistory();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.update.full]);

    const submit = () => {
        if (brandName !== '') {
            setEditing(false);
            if (brandName !== brand.brandName) {
                const updatedBrand = { ...brand, brandName };
                saveBrand({ brand: updatedBrand, msg: 'Brand name updated!' });
                updateBrand(updatedBrand);
            }
        }
    };

    const handleChange = (ev) => {
        setBrandName(ev.target.value);
    };

    const onBlur = (ev) => {
        submit();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    return (
        <>
            {isEditing ? (
                <Grid container direction='row' justify='flex-start' alignItems='center'>
                    <div style={{ flex: '0 0 auto'}}>
                        <IconButton aria-label='Go Back' edge='start' onClick={() => history.goBack()}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </div>
                    <div style={{ flex: '1 1 auto'}}>
                        <TextField
                            placeholder='Brand Name'
                            inputProps={{ 'aria-label': 'Brand Name' }}
                            autoFocus
                            fullWidth
                            value={brandName}
                            name='brandName'
                            onBlur={onBlur}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className={classes.input}
                            error={brandName === ''}
                        />
                        {brandName === '' &&
                            <FormHelperText error={brandName === ''}>This field is required</FormHelperText>
                        }
                    </div>
                </Grid>
            ) :(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '0 0 auto' }}>
                        <IconButton aria-label='Go Back' edge='start' onClick={() => history.goBack()}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </div>
                    <div style={{ flex: '1 1 auto' }}>
                        <Typography
                            component='span'
                            variant='h3'
                            {...(canEdit &&
                                {onClick: () => setEditing(true)}
                            )}
                        >
                            {brandName}
                            <AccessControl allowedPermissions={[permissions.brands.update.full]}>
                                <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft} onClick={() => setEditing(true)}>
                                    <CreateSharpIcon fontSize='small' color='secondary' />
                                </IconButton>
                            </AccessControl>
                        </Typography>
                    </div>
                </div>
            )}
        </>
    );
}

BrandName.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func
};

export default BrandName;
