const permissions = {
    brands: {
        list: 'brands:list',
        detail: 'brands:detail',
        create: 'brands:create',
        update: {
            basic: 'brands:update:basic',
            full: 'brands:update:full',
            searchStrategist: 'brands:update:searchStrategist',
        }
    },
    users: {
        list: 'users:list',
        self: 'users:self',
        create: 'users:create',
        update: 'users:update'
    },
    agencies: {
        list: 'agencies:list',
        create: 'agencies:create',
        update: 'agencies:update',
    },
    reports: {
        generate: 'reports:generate'
    },
    violations: {
        list: 'violations:list',
        view: 'violations:view',
    }
};

export default permissions;
