import roles from 'roles';
import permissions from 'permissions';

const rules = {
    [roles.SEARCH_STRATEGIST.name]: {
        static: [
            permissions.brands.list,
            permissions.brands.detail,
            permissions.brands.update.basic,
            permissions.reports.generate,
            permissions.violations.list,
            permissions.violations.view,
            permissions.users.self,
        ]
    },
    [roles.AGENCY_MANAGER.name]: {
        static: [
            permissions.brands.list,
            permissions.brands.detail,
            permissions.brands.update.searchStrategist,
            permissions.brands.update.basic,
            permissions.users.list,
            permissions.users.update,
            permissions.users.create,
            permissions.reports.generate,
            permissions.violations.list,
            permissions.violations.view,
        ]
    },
    [roles.SYSTEM_ADMINISTRATOR.name]: {
        static: [
            permissions.brands.list,
            permissions.brands.detail,
            permissions.brands.create,
            permissions.brands.update.searchStrategist,
            permissions.brands.update.basic,
            permissions.brands.update.full,
            permissions.users.list,
            permissions.users.create,
            permissions.users.update,
            permissions.agencies.list,
            permissions.agencies.update,
            permissions.agencies.create,
            permissions.reports.generate,
            permissions.violations.list,
        ]
    }
};

export default rules;
