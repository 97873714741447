import {Chip, TextField} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";

function BrandDomainUrlsSelect(props) {

    const {brand, brandDomainFilter, setBrandDomainFilter} = props;

    const brandIds = brand.flatMap(brand => (brand !== null) ? brand.id : [{id: -1, url: 'All'}]);

    const {brandUrls} = props;
    const urlOptions = (brandUrls !== null) ?
        brandUrls.filter(option =>
            brandIds.includes(option.brandId) ||
            option.brandId === null ||
            brandIds.length === 0
        ) :
        [];

    const handleBrandDomainFilterChange = (event, newValue, reason) => {
        setBrandDomainFilter(newValue.filter(option => option.id !== -1))
    };

    const handleBrandDomainFilterDelete = (event, item) => {
        setBrandDomainFilter(
            brandDomainFilter.filter(
                option => option.id !== item.id
            )
        );
    };

    return (
        <>
            <Autocomplete
                multiple
                id='brand-domain-select'
                options={urlOptions}
                getOptionLabel={(option) => option.url}
                autoHighlight
                getOptionSelected={
                    (option, brandDomainFilter) => {
                        return option.id === brandDomainFilter.id
                    }
                }
                filterSelectedOptions
                value={brandDomainFilter.length === 0 ? [{id: -1, url: 'All'}] : brandDomainFilter}
                onChange={
                    (event, newValue, reason) =>
                        handleBrandDomainFilterChange(event, newValue, reason)
                }
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                            <Chip
                                {...(option.id !== -1 && getTagProps({index}))}
                                key={option.id}
                                label={option.url}
                                size='small'
                                {...(option.id !== -1 && {onDelete: ((event) => handleBrandDomainFilterDelete(event, option))})}

                            />
                        )
                    )}
                renderInput={params => (
                    <TextField {...params} label='Select Brand Domain URLs'/>
                )}
            />
        </>
    );
}

BrandDomainUrlsSelect.propTypes = {
    brand: PropTypes.array,
    brandDomainFilter: PropTypes.array,
    setBrandDomainFilter: PropTypes.func
};

export default BrandDomainUrlsSelect;

