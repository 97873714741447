import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'

import {makeStyles} from '@material-ui/core/styles';
import {
    CardContent,
    CardHeader,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import {Storage} from 'aws-amplify';
import PrintIcon from '@material-ui/icons/Print';
import {format} from 'date-fns';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    media: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1.5)
    },
    image: {
        width: theme.spacing(30)
    },
    complianceStatement: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    screenshotError: {
        paddingTop: theme.spacing(2)
    }
}));

function ViolationComponent(props) {

    const classes = useStyles();
    const { adViolation, hidePrintButton } = props;
    const [loadingImage, setLoadingImage] = useState(true);
    const [imageURL, setImageURL] = useState('');
    const [imageError, setImageError] = useState(false);
    let history = useHistory();

    useEffect(() => {
        if (adViolation.adScreenshotUrl !== undefined) {
            const regex = /\/([^/]+)\/(.*)$/;
            const [, bucket, key] = adViolation.adScreenshotUrl.match(regex);
            Storage.get(key, { bucket, download: false, level: 'public', customPrefix: { public: '' } })
                .then(result => {
                    setImageURL(result);
                    setLoadingImage(false);
                })
                .catch(err => {
                    console.log(err);
                    setImageError(true);
                    setLoadingImage(false)
                });
        }
    }, []);

    return (
        <div className='adViolationReport'>
            <div className={classes.media} >
                <img className={classes.image} src='/images/weblogo_transparent.png' alt='AdComplyRx logo' />
            </div>

            {!hidePrintButton &&
                <CardHeader
                    action={
                        <IconButton aria-label='Print' onClick={() => window.print()} className='noPrint'>
                            <PrintIcon />
                        </IconButton>
                    }
                    title='Brand Non-Compliance Report'
                />
            }

            <CardContent>
                <Typography variant='h4' gutterBottom>
                    Brand: {adViolation.brandName}
                </Typography>
                <Typography variant='body1'>
                    Captured on: {adViolation.adCaptureDate ? format(new Date(adViolation.adCaptureDate), "MMM d, yyyy 'at' h:mmaaaa") : null}
                    <br/>
                    Search Provider: {adViolation.adProvider? adViolation.adProvider : null}
                    <br/>
                    Keywords: {adViolation.searchTerms}
                </Typography>
                <Typography variant='body1' className={classes.complianceStatement} color='error'>
                    {adViolation.complianceStatements.join(' ')}
                </Typography>
                <Table size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell>Headline</TableCell>
                            <TableCell>{adViolation.adHeadline}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Display URL</TableCell>
                            <TableCell>{adViolation.adDisplayUrl}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Advertiser URL</TableCell>
                            <TableCell>{adViolation.advertiserUrl}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Description Line / Callout</TableCell>
                            <TableCell>{adViolation.adDescriptionLine}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Site Snippet</TableCell>
                            <TableCell>{adViolation.adSiteSnippet}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Separate Callouts</TableCell>
                            <TableCell>{adViolation.adSeparateCallouts}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sitelinks</TableCell>
                            <TableCell>{adViolation.adSiteLinks}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Expanded Sitelinks</TableCell>
                            <TableCell>{adViolation.adExpandedSitelinks}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {!loadingImage && !imageError &&
                    <img src={imageURL} className='adScreenshotImg' />
                }
                {loadingImage &&
                    <span>Loading image...</span>
                }
                {imageError &&
                    <span>Error loading image: {adViolation.adScreenshotUrl}</span>
                }
            </CardContent>
        </div>
    );
}

ViolationComponent.propTypes = {
    adViolation: PropTypes.object,
    hidePrintButton: PropTypes.bool
};

export default ViolationComponent;
