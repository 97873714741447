import {useEffect, useState} from 'react';
import axios from 'axios';
import routes from 'routes';

export default () => {
    const [isLoading, setIsLoading] = useState(true);
    const [brandUrls, setBrandUrls] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.brandUrls.endpoint}`);
                setBrandUrls(result.data.data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        fetchData();
    }, []);

    return {isLoading, brandUrls: brandUrls};
}
