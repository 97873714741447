import React from 'react';

import {useHistory} from 'react-router-dom';
import {Button, Card, CardContent, CardHeader, Divider, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import routes from 'routes';
import {useSelector} from 'react-redux';
import AccessControl, {check} from '../../RBAC/AccessControl';
import rules from '../../../rbac-rules';
import permissions from '../../../permissions';
import MaterialUITable from '../../Custom/MaterialUITable';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    addButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
}));

export default function AgenciesTableComponent(props) {

    const { agencies } = props;
    let history = useHistory();
    const classes = useStyles();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.agencies.update]);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card variant='outlined'>
                        <CardHeader
                            title={routes.agencies.pageTitle}
                            subheader='The following agencies have been configured in AdComplyRx.
                            You can add additional agencies or edit agencies from this screen.'
                            action={
                                <AccessControl allowedPermissions={[permissions.agencies.create]}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.addButton}
                                        onClick={
                                            () => history.push(`${routes.agencies.path}/new`)
                                        }
                                    >
                                        Add Agency
                                    </Button>
                                </AccessControl>
                            }
                        />
                        <Divider/>
                        <CardContent className={classes.cardContent}>
                            <MaterialUITable
                                data={agencies}
                                columns={[
                                    {title: 'Agency Name', field: 'agencyName'},
                                    {
                                        title: 'Brands', field: 'brands',
                                        customFilterAndSearch: (term, rowData) =>
                                            rowData.brands === null ? false : (rowData.brands.toString().includes(term))
                                    }, {
                                    title: 'Search Terms', field: 'searchTerms',
                                    customFilterAndSearch: (term, rowData) =>
                                        rowData.searchTerms === null ? false : (rowData.searchTerms.toString().includes(term))
                                }, {
                                    title: 'Users', field: 'users',
                                    customFilterAndSearch: (term, rowData) =>
                                        rowData.users === null ? false : (rowData.users.toString().includes(term))
                                }, {
                                    title: 'Status',
                                    field: 'agencyActive',
                                    render: rowData => (
                                        rowData.agencyActive ? 'Active' : 'Inactive'
                                    ),
                                    lookup: { true: 'Active', false: 'Inactive' }
                                },]}
                                {...(canEdit &&
                                    {onRowClick: ((event, rowData) => {
                                            let agencyId = rowData.agencyId;
                                            history.push(`${routes.agencies.path}/${agencyId}`);
                                        })}
                                )}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}