import { useSelector } from 'react-redux';
import rules from 'rbac-rules';

export const check = (rules, role, actions) => {

    const permissions = rules[role];

    if (!permissions) {
        return false;
    }

    if (actions.length === 0) {
        return true;
    }

    // Check static permissions
    const staticPermissions = permissions.static;
    if (staticPermissions) {
        return actions.some(action => staticPermissions.includes(action));
    }

    return false;
};

const AccessControl = props => {
    const { currentUser } = useSelector(state => state.users);
    return check(rules, currentUser.role, props.allowedPermissions)
        ? props.children
        : props.noAccess();
};

AccessControl.defaultProps = {
    noAccess: () => null
};

export default AccessControl;
