import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';

import ReportSearchForm from 'components/Reports/SearchForm';
import Main from 'components/Layout/Main';
import qs from 'qs';

import {setReport} from 'actions';
import routes from 'routes';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useBrands from 'api/useBrands';
import useUsers from 'api/useUsers';
import useRules from "../../../api/useRules";
import useAdProviders from "../../../api/useAdProviders";
import useBrandUrls from "../../../api/useBrandUrls";
import useSearchTerm from "../../../api/useSearchTerm";
import LinearProgress from "@material-ui/core/LinearProgress";

function ReportsSearchContainer(props) {

    const [loadingReport, setLoadingReport] = useState(false);

    const {isLoading: brandLoading, brands} = useBrands({}, [], true);
    const {isLoading: userLoading, users} = useUsers({}, [], true);
    const {isLoading: ruleLoading, rules} = useRules();
    const {isLoading: adProviderLoading, adProvider} = useAdProviders();
    const {isLoading: brandUrlsLoading, brandUrls} = useBrandUrls();
    const {isLoading: searchTermLoading, searchTerm} = useSearchTerm();

    const generateReport = (params) => {
        let queryString = qs.stringify(params, {arrayFormat: 'repeat'})
        let url = `${window.location.origin}${routes.reports.path}/${params.report}?${queryString} `
        let win = window.open(url, '_blank');
        win.focus();
    };

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.reports.generate]} noAccess={() => <Forbidden/>}>
                {brandLoading || userLoading || ruleLoading || adProviderLoading || brandUrlsLoading || searchTermLoading ? (
                    <LinearProgress color="secondary" thickness={4}/>
                ) : (
                    <ReportSearchForm
                        generateReport={generateReport}
                        loadingReport={loadingReport}
                        users={users}
                        brands={brands}
                        rules={rules}
                        adProvider={adProvider}
                        brandUrls={brandUrls}
                        searchTerm={searchTerm}
                    />
                )}
            </AccessControl>
        </Main>

    );
}

ReportsSearchContainer.propTypes = {
    setReport: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    setReport: report => dispatch(setReport(report))
});

export default connect(null, mapDispatchToProps)(ReportsSearchContainer);
