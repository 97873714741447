import {Chip, TextField} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";

function RuleSelect(props) {

    const {rule, setRule} = props;

    const handleRuleChange = (event, newValue, reason) => {
        setRule(newValue.filter(option => option.id !== -1));
    };

    const handleRuleDelete = (event, item) => {
        setRule(rule.filter(option => option.id !== item.id));
    };

    return (
        <>
            <Autocomplete
                multiple
                id='rule-select'
                options={(props.rules !== null) ? props.rules : []}
                getOptionLabel={(option) => option.name}
                autoHighlight
                getOptionSelected={
                    (option, rule) => {
                        return option.id === rule.id
                    }
                }
                filterSelectedOptions
                value={rule.length === 0 ? [{id: -1, name: 'All'}] : rule}
                onChange={
                    (event, newValue, reason) =>
                        handleRuleChange(event, newValue, reason)
                }
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                            <Chip
                                {...(option.id !== -1 && getTagProps({index}))}
                                key={option.id}
                                label={option.name}
                                size='small'
                                {...(option.id !== -1 && {onDelete: ((event) => handleRuleDelete(event, option))})}
                            />
                        )
                    )}
                renderInput={params => (
                    <TextField {...params} label='Select Rule'/>
                )}
            />
        </>
    );

}

RuleSelect.porpTypes = {
    rule: PropTypes.string,
    setRule: PropTypes.func,
}

export default RuleSelect;
