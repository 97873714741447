import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {
    Typography,
    makeStyles,
    Chip,
    IconButton,
    TextField,
    FormHelperText
} from '@material-ui/core';

import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import {check} from 'components/RBAC/AccessControl';
import rules from 'rbac-rules';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
    }
}));

function SearchStrategists(props) {

    const classes = useStyles();
    const [isEditing, setEditing] = useState(false);
    const [readyToSave, setReadyToSave] = useState(false);
    const [firstTimeClick, setFirstTimeClick] = useState(true);
    const { brand, updateBrand, saveBrand } = props;
    const [strategists, setStrategists] = useState([]);
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.update.searchStrategist]);


    useEffect(() => {
        // Set the brand
        setStrategists(brand.searchStrategists === null ? [] : brand.searchStrategists);
        setEditing(brand.searchStrategists === null || brand.searchStrategists.length === 0);
    }, [brand]);

    const submit = () => {
        if (strategists.length !== 0) {
            setEditing(false);
            setReadyToSave(false);
            setFirstTimeClick(true);
            if(strategists !== brand.searchStrategists) {
                const updatedBrand = { ...brand, searchStrategists: strategists };
                updateBrand(updatedBrand);
                saveBrand({ brand: updatedBrand, msg: 'Search strategists updated!' });
            }
        }
    };

    const onBlur = () => {
        submit(brand);
    };

    const handleOnKeyDown = (event) => {
        if (event.key === 'Enter' && readyToSave) {
            submit(brand);
        } else if (event.key === 'Enter' && firstTimeClick) {
            submit(brand);
        } else {
            setFirstTimeClick(false);
        }
    };

    const handleSearchStrategistChange = (event, newValue, reason) => {
        setFirstTimeClick(false);
        setStrategists(newValue);
    };

    const handleSearchStrategistDelete = (event, strategist) => {
        setFirstTimeClick(false);
        setStrategists(strategists.filter(option => option.id !== strategist.id));
        setReadyToSave(true);
    };

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Search Strategists
            </Typography>

            {isEditing ? (
                <Autocomplete
                    multiple
                    filterSelectedOptions
                    id='search-strategist'
                    options={props.strategists}
                    onKeyDown={handleOnKeyDown}
                    value={strategists}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, searchStrategist) =>
                        (option.id === searchStrategist.id)}
                    onChange={(event, newValue, reason) =>
                        handleSearchStrategistChange(event, newValue, reason)}
                    onInputChange={(event, newValue, reason) => setReadyToSave(newValue === '')}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                key={option.id}
                                label={option.name}
                                onDelete={(event) => handleSearchStrategistDelete(event, option)}
                                size='small'
                            />))}
                    renderInput={params => (
                        <>
                            <TextField {...params} onBlur={onBlur} size='small' error={strategists.length === 0} autoFocus />
                            {strategists.length === 0 &&
                                <FormHelperText error={strategists.length === 0}>Please select search strategists</FormHelperText>
                            }
                        </>)}
                />
            ) : (
                <div className={classes.chipRoot} {...(canEdit && {onClick: () => setEditing(true)})}>
                    {strategists.map(strategist => (
                        <Chip key={strategist.id} label={strategist.name} size='small' />
                    ))}
                    <AccessControl allowedPermissions={[permissions.brands.update.searchStrategist]}>
                        <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft} onClick={() => setEditing(true)}>
                            <CreateSharpIcon fontSize='small' color='secondary' />
                        </IconButton>
                    </AccessControl>
                </div>
            )}
        </>
    );
}

SearchStrategists.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func
};

export default SearchStrategists;
