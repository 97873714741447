import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';

import Main from 'components/Layout/Main';
import UserCreateComponent from 'components/Users/Create';

import {successToast, errorToast} from 'actions';
import routes from 'routes';
import roles from 'roles';

import permissions from 'permissions';
import AccessControl from 'components/RBAC/AccessControl';

import LinearProgress from '@material-ui/core/LinearProgress';
import Forbidden from 'components/Forbidden';
import useAgencies from 'api/useAgencies';

function CreateUserContainer(props) {

    let history = useHistory();

    // Load all the agencies
    const {isLoading: isLoadingAgencies, agencies} = useAgencies();
    const [error, setError] = useState(
        {
            field: "",
            message: "",
        }
    );

    const saveUser = async (user) => {
        await axios.post(routes.users.endpoint, user)
            .then(() => {
                props.successToast(`${user.name} successfully created!`);
                history.push(routes.users.path);
            })
            .catch(error => {
                // "1 validation error detected: Value at 'temporaryPassword' failed to satisfy constraint: Member must have length greater than or equal to 6 (Service: AWSCognitoIdentityProvider; Status Code: 400; Error Code: InvalidParameterException; Request ID: 887e7a9a-4996-4502-8878-0eec8c108650)"
                // "Invalid email address format. (Service: AWSCognitoIdentityProvider; Status Code: 400; Error Code: InvalidParameterException; Request ID: 99304bb4-3dc2-4cef-a7d7-34977c78e406)"
                // "ERROR: duplicate key value violates unique constraint "users_email_key"
                //     Detail: Key (email)=(badpass@email.com) already exists."   <-- accessed with error.response.data.data.message
                // "Password did not conform with password policy: Password not long enough (Service: AWSCognitoIdentityProvider; Status Code: 400; Error Code: InvalidPasswordException; Request ID: cfdcd21f-ad16-4f46-929e-8dba759dd6bc)"
                // "Password did not conform with password policy: Password must have uppercase characters (Service: AWSCognitoIdentityProvider; Status Code: 400; Error Code: InvalidPasswordException; Request ID: 9d72a91b-4fce-422f-8bf4-7d6251ba3f36)"
                // "Password did not conform with password policy: Password must have numeric characters (Service: AWSCognitoIdentityProvider; Status Code: 400; Error Code: InvalidPasswordException; Request ID: 79b3850e-940f-4409-a39a-58c3eedaaa44)"
                // "Password did not conform with password policy: Password must have symbol characters (Service: AWSCognitoIdentityProvider; Status Code: 400; Error Code: InvalidPasswordException; Request ID: 5d9a7149-952c-4114-b7ee-96bc73acd2a5)"
                //
                let msg = error.response.data.message
                let err;
                if (msg.includes('email') || msg.includes('username')) {
                    err = {
                        field: 'email',
                        message: msg.includes('username') ?
                            'Email cannot contain spaces' :
                            msg.includes('(') ?
                                msg.substring(0, msg.indexOf('(')) :
                                msg,
                    }
                } else if (msg.includes('Password')) {
                    err = {
                        field: 'password',
                        message: msg.substring(msg.indexOf(':') + 1, msg.indexOf('(') - 1),
                    }
                } else {
                    err = {
                        field: '',
                        message: '',
                    }
                    props.errorToast(msg)
                }
                setError(err);
            })
    };

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.users.create]} noAccess={() => <Forbidden/>}>
                {isLoadingAgencies ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <UserCreateComponent agencies={agencies} roles={roles} saveUser={saveUser} error={error}/>
                )}
            </AccessControl>
        </Main>
    );
}

const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg))
});

export default connect(null, mapDispatchToProps)(CreateUserContainer);
