import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import routes from 'routes';
import permissions from 'permissions';
import rules from 'rbac-rules';
import {check} from 'components/RBAC/AccessControl';

export default (agencyId) => {
    const [isLoading, setIsLoading] = useState(true);
    const [agency, setAgency] = useState(null);
    const {role} = useSelector(state => state.users.currentUser);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.agencies.endpoint}/${agencyId}`);
                setAgency(result.data.data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        if (check(rules, role, [permissions.agencies.list, permissions.agencies.update])) {
            setIsLoading(true);
            fetchData();
        }
    }, []);

    return {isLoading, agency};
}
