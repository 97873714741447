import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {FormControl, FormHelperText} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";


function FromDate(props) {

    const {fromDate, fromDateError, setFromDate, setFromDateError} = props;

    const handleFromDateChange = (date) => {
        setFromDate(date);
        setFromDateError(false);
    };

    return(
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    format="MMMM do, yyyy"
                    disableFuture
                    variant='inline'
                    label='From Date'
                    value={fromDate}
                    onChange={handleFromDateChange}
                    size='small'
                    error={fromDateError}
                />
            </MuiPickersUtilsProvider>
            <FormHelperText>From date required</FormHelperText>
        </>
    );

}

FromDate.propTypes = {
    fromDate: PropTypes.object,
    fromDateError: PropTypes.bool,
    setFromDate: PropTypes.func,
    setFromDateError: PropTypes.func,
};

export default FromDate;