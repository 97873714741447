import React from 'react';

import ViolationsTableComponent from 'components/Violations/List';
import Main from 'components/Layout/Main';
import AccessControl from "../../../components/RBAC/AccessControl";
import permissions from "../../../permissions";
import useViolations from "../../../api/useViolations";
import LinearProgress from "@material-ui/core/LinearProgress";
import useViolationsCount from "../../../api/useViolationsCount";
import Forbidden from "../../../components/Forbidden";
import {useSelector} from "react-redux";

function ViolationsContainer(props) {

    const {isLoading, violations} = useViolations();
    const {violationsCount} = useSelector(state => state.violationsCount);

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.violations.list]} noAccess={() => <Forbidden/>}>
                {isLoading ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <ViolationsTableComponent violations={violations} violationsCount={violationsCount}/>
                )}
            </AccessControl>
        </Main>
    );
}

export default ViolationsContainer;
