import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {startOfDay} from 'date-fns';

import PropTypes from 'prop-types';
import {Button, Card, CardContent, CardHeader, Divider, FormControl, Grid,} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import roles from "../../../roles";
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import {useSelector} from 'react-redux';
import ReportSelect from "./ReportSelect";
import FromDate from "./FromDate";
import ToDate from "./ToDate";
import SearchStrategistSelect from "./SearchStrategistSelect";
import BrandSelect from "./BrandSelect";
import RuleSelect from "./RuleSelect";
import SearchEngineSelect from "./SearchEngineSelect";
import BrandDomainUrlsSelect from "./BrandDomainUrlsSelect";
import SearchTermsSelect from "./SearchTermsSelect";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    downloadReportButton: {
        '&$downloadReportButtonDisabled': {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.dark,
            opacity: 0.6,
            boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)'
        },
    },
    downloadReportButtonDisabled: {},
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

function ReportSearchForm(props) {

    let location = useLocation();
    let params = qs.parse(location.search, {ignoreQueryPrefix: true, comma: true});

    const {currentUser} = useSelector(state => state.users);
    const [report, setReport] = useState('brandcompliance');
    const [fromDate, setFromDate] = useState(
        (params.fromDate) ?
            startOfDay(new Date(params.fromDate)) :
            null
    );
    const [toDate, setToDate] = useState(
        (params.toDate) ?
            startOfDay(new Date(params.toDate)) :
            null
    );
    const [strategist, setStrategist] = useState(
        currentUser.role == roles.SEARCH_STRATEGIST.name ?
            [currentUser] :
            (params.strategist) ?
                props.users.filter(option =>
                    Array.isArray(params.strategist) ?
                        params.strategist.some(item => parseInt(item) === option.id) :
                        parseInt(params.strategist) === option.id
                ) :
                []
    );
    const [brand, setBrand] = useState(
        (params.brand) ?
            props.brands.filter(option =>
                Array.isArray(params.brand) ?
                    params.brand.some(item => parseInt(item) === option.id) :
                    parseInt(params.brand) === option.id
            ) :
            []
    );
    const [reportError, setReportError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    const [toDateError, setToDateError] = useState(false);
    const [rule, setRule] = useState(
        (params.rule) ?
            props.rules.filter(option =>
                Array.isArray(params.rule) ?
                    params.rule.some(item => parseInt(item) === option.id) :
                    parseInt(params.rule) === option.id
            ) :
            []
    );
    const [searchEngineFilter, setSearchEngineFilter] = useState(
        (params.adProvider) ?
            props.adProvider.filter(option =>
                Array.isArray(params.adProvider) ?
                    params.adProvider.some(item => item === option.id) :
                    params.adProvider === option.id
            ) :
            []
    );
    const [brandDomainFilter, setBrandDomainFilter] = useState(
        (params.brandUrl) ?
            props.brandUrls.filter(option =>
                Array.isArray(params.brandUrl) ?
                    params.brandUrl.some(item => item === option.id) :
                    params.brandUrl === option.id
            ) :
            []
    );
    const [searchTermFilter, setSearchTermFilter] = useState(
        (params.searchTerm) ?
            props.searchTerm.filter(option =>
                Array.isArray(params.searchTerm) ?
                    params.searchTerm.some(item => item === option.id) :
                    params.searchTerm === option.id
            ) :
            []
    );

    const submit = (event) => {
        event.preventDefault();

        const isReportSet = report !== '';
        const isFromDateSet = fromDate !== null && fromDate !== '';
        const isToDateSet = toDate !== null && toDate !== '';

        setReportError(!isReportSet);
        setFromDateError(!isFromDateSet);
        setToDateError(!isToDateSet);

        if (isReportSet && isFromDateSet && isToDateSet) {
            let formattedFromDate = fromDate;
            let formattedToDate = toDate;

            if (fromDate instanceof Date) {
                formattedFromDate = startOfDay(new Date(fromDate.getTime()));
            }
            if (toDate instanceof Date) {
                formattedToDate = startOfDay(new Date(toDate.getTime()));
            }
            props.generateReport(
                {
                    report,
                    fromDate: formattedFromDate,
                    toDate: formattedToDate,
                    strategist: strategist.map(option => option.id),
                    brand: brand.map(options => options.id),
                    rule: rule.map(options => options.id),
                    adProvider: searchEngineFilter.map(options => options.id),
                    brandUrl: brandDomainFilter.map(options => options.id),
                    searchTerm: searchTermFilter.map(options => options.id),
                }
            );
        }
    };

    const classes = useStyles();

    return (
        <>
            {props.loadingReport && <LinearProgress color='secondary'/>}
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={10} lg={8} xl={6}>
                        <Card>
                            <CardHeader title='Reports'/>
                            <Divider/>
                            <CardContent>
                                <form onSubmit={submit}>
                                    <Grid container spacing={3}>
                                        {/* Select Report Type */}
                                        <Grid item xs={12}>
                                            <FormControl className={classes.formControl} error={reportError}>
                                                <ReportSelect report={report} setReport={setReport}/>
                                            </FormControl>
                                        </Grid>
                                        {/* From Date */}
                                        <Grid item xs={12} sm={6}>
                                            <FormControl className={classes.formControl} error={fromDateError}>
                                                <FromDate
                                                    fromDate={fromDate}
                                                    fromDateError={fromDateError}
                                                    setFromDate={setFromDate}
                                                    setFromDateError={setFromDateError}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* To Date */}
                                        <Grid item xs={12} sm={6}>
                                            <FormControl className={classes.formControl} error={toDateError}>
                                                <ToDate
                                                    toDate={toDate}
                                                    toDateError={toDateError}
                                                    setToDate={setToDate}
                                                    setToDateError={setToDateError}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* Search Strategist */}
                                        <Grid item xs={12}>
                                            <FormControl className={classes.formControl}>
                                                <SearchStrategistSelect
                                                    strategist={strategist}
                                                    setStrategist={setStrategist}
                                                    {...props}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* Brand Selection */}
                                        <Grid item xs={12}>
                                            <FormControl className={classes.formControl}>
                                                <BrandSelect
                                                    brand={brand}
                                                    setBrand={setBrand}
                                                    {...props}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* Rule Filter Selection */}
                                        <Grid item xs={12}>
                                            <FormControl className={classes.formControl}>
                                                <RuleSelect
                                                    rule={rule}
                                                    setRule={setRule}
                                                    {...props}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* Search Engine Selection */}
                                        <Grid item xs={12}>
                                            <FormControl className={classes.formControl}>
                                                <SearchEngineSelect
                                                    searchEngineFilter={searchEngineFilter}
                                                    setSearchEngineFilter={setSearchEngineFilter}
                                                    {...props}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* Brand Domain Selection */}
                                        <Grid item xs={12}>
                                            <FormControl className={classes.formControl}>
                                                <BrandDomainUrlsSelect
                                                    brand={brand}
                                                    brandDomainFilter={brandDomainFilter}
                                                    setBrandDomainFilter={setBrandDomainFilter}
                                                    {...props}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* Search Term Selection */}
                                        <Grid item xs={12}>
                                            <FormControl className={classes.formControl}>
                                                <SearchTermsSelect
                                                    brand={brand}
                                                    searchTermFilter={searchTermFilter}
                                                    setSearchTermFilter={setSearchTermFilter}
                                                    {...props}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* Generate Report Button*/}
                                        <Grid item>
                                            <FormControl className={classes.formControl}>
                                                <Button variant='contained' disabled={props.loadingReport}
                                                        color='secondary'
                                                        classes={{
                                                            root: classes.downloadReportButton,
                                                            disabled: classes.downloadReportButtonDisabled
                                                        }}
                                                        type='submit'>
                                                    Generate Report
                                                </Button>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

ReportSearchForm.propTypes = {
    generateReport: PropTypes.func,
    loadingReport: PropTypes.bool,
    brands: PropTypes.array,
    users: PropTypes.array,
    rules: PropTypes.array,
    adProvider: PropTypes.array,
    brandUrls: PropTypes.array,
    searchTerm: PropTypes.array,
};

export default ReportSearchForm;
