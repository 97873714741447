import React from 'react';

import BrandsTableComponent from 'components/Brands/List';
import Main from 'components/Layout/Main';

import LinearProgress from '@material-ui/core/LinearProgress';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useBrands from 'api/useBrands';

function BrandsContainer() {

    const { isLoading, brands } = useBrands({}, [], true);

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.brands.list]} noAccess={() => <Forbidden/>}>
                {isLoading ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <BrandsTableComponent brands={brands}/>
                )}
            </AccessControl>
        </Main>
    );
}

export default BrandsContainer;