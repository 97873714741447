import {Chip, TextField} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";

function SearchEngineSelect(props) {

    const {searchEngineFilter, setSearchEngineFilter} = props;

    const handleSearchEngineFilterChange = (event, newValue, reason) => {
        setSearchEngineFilter(newValue.filter(option => option.id !== -1));
    };

    const handleSearchEngineFilterDelete = (event, item) => {
        setSearchEngineFilter(
            searchEngineFilter.filter(
                option => option.id !== item.id
            )
        );
    };

    return (
        <>
            <Autocomplete
                multiple
                id='search-engine-select'
                options={props.adProvider !== null ? props.adProvider : []}
                getOptionLabel={(option) => option.name}
                autoHighlight
                getOptionSelected={
                    (option, searchEngineFilter) => {
                        return option.id === searchEngineFilter.id
                    }
                }
                filterSelectedOptions
                value={searchEngineFilter.length === 0 ? [{id: -1, name: 'All'}] : searchEngineFilter}
                onChange={
                    (event, newValue, reason) =>
                        handleSearchEngineFilterChange(event, newValue, reason)
                }
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                            <Chip
                                {...(option.id !== -1 && getTagProps({index}))}
                                key={option.id}
                                label={option.name}
                                size='small'
                                {...(option.id !== -1 && {onDelete: ((event) => handleSearchEngineFilterDelete(event, option))})}
                            />
                        )
                    )}
                renderInput={params => (
                    <TextField {...params} label='Select Search Engine'/>
                )}
            />
        </>
    );
}

SearchEngineSelect.propTypes = {
    searchEngineFilter: PropTypes.array,
    setSearchEngineFilter: PropTypes.func,
}

export default SearchEngineSelect;
