import React from 'react';

import UsersTableComponent from 'components/Users/List';
import Main from 'components/Layout/Main';
import LinearProgress from '@material-ui/core/LinearProgress';
import permissions from 'permissions';
import AccessControl from 'components/RBAC/AccessControl';
import Forbidden from 'components/Forbidden';
import useUsers from 'api/useUsers';

function UsersListContainer() {

    const { isLoading, users } = useUsers({}, [], true);

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.users.list]} noAccess={() => <Forbidden />}>
                {isLoading ? (
                    <LinearProgress color='secondary' />
                ) : (
                    <UsersTableComponent users={users} />
                )}
            </AccessControl>
        </Main>
    );
}

export default UsersListContainer;
