import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Typography } from '@material-ui/core';
import { NotificationsOutlined as NotificationsIcon, Input as InputIcon, Menu as MenuIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import routes from 'routes';
import AccessControl from '../../../../RBAC/AccessControl';
import permissions from '../../../../../permissions';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    color: theme.palette.white
  },
  logo: {
    width: theme.spacing(7)
  },
  logoTitle: {
    color: theme.palette.white,
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const {violationsCount} = useSelector(state=> state.violationsCount);
  const classes = useStyles();

  const signOut = () => {
    Auth.signOut()
        .then(data => {})
        .catch(err => {});
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to='/'>
          <img className={classes.logo} alt='Logo' src='/images/weblogo_transparent_logo.png'/>
        </RouterLink>
        <RouterLink to='/' style={{textDecoration: 'none'}}>
          <Typography className={classes.logoTitle}>AdComplyRx™</Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden lgUp>

          <IconButton
              color='inherit'
              onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden >
          <AccessControl allowedPermissions={[permissions.violations.list]}>
            <RouterLink to={routes.violations.path}>
              <IconButton>
                <Badge badgeContent={(violationsCount > 0 ? violationsCount : null )} color='error'>
                  <NotificationsIcon className={classes.notificationsButton} />
                </Badge>
              </IconButton>
            </RouterLink>
          </AccessControl>
          <IconButton
            className={classes.signOutButton}
            color='inherit'
            onClick={signOut}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
