import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {FormControl, FormHelperText} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";


function ToDate(props) {

    const {toDate, toDateError, setToDate, setToDateError} = props;

    const handleToDateChange = (date) => {
        setToDate(date);
        setToDateError(false);
    };

    return(
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    format="MMMM do, yyyy"
                    disableFuture
                    variant='inline'
                    label='To Date'
                    value={toDate}
                    onChange={handleToDateChange}
                    size='small'
                    error={toDateError}
                />
            </MuiPickersUtilsProvider>
            <FormHelperText>To date required</FormHelperText>
        </>
    );

}

ToDate.propTypes = {
    toDate: PropTypes.object,
    toDateError: PropTypes.bool,
    setToDate: PropTypes.func,
    setToDateError: PropTypes.func,
};

export default ToDate;