import { Grid, Switch, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { check } from "../../RBAC/AccessControl";
import rules from "../../../rbac-rules";
import { useSelector } from "react-redux";
import permissions from "../../../permissions";
import React, { useState } from "react";

{/** Save and update WeeklyReport search */ }

function WeeklyReportEnabled(props) {

    const { brand, updateBrand, saveBrand } = props;

    const [weeklyReportEnabled, setWeeklyReportEnabled] = useState(brand.weeklyReportEnabled);
    const { role } = useSelector(state => state.users.currentUser);



    const handleWeeklyReportEnabledChange = (event) => {
        setWeeklyReportEnabled(event.target.checked)
        const updatedBrand = { ...brand, weeklyReportEnabled: event.target.checked };
        saveBrand({ brand: updatedBrand, msg: 'WeeklyReport enabled updated!' });
        updateBrand(updatedBrand);
    };

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Enable Weekly Report
            </Typography>
            <Grid container>
            <Grid component="label" container alignItems="center">
                <Grid item>Off</Grid>
                <Grid item>
                    <Switch
                        id="weeklyReportEnabled"
                        checked={weeklyReportEnabled}
                        onChange={event => handleWeeklyReportEnabledChange(event)}
                        name="weeklyReportEnabled"
                        disabled={!check(rules, role, [permissions.brands.update.basic,])}
                    />
                </Grid>
                <Grid item>On </Grid></Grid>
                <Grid container>
                {weeklyReportEnabled && (
                    <Typography variant="body2" color="textSecondary">
                        [Report would be sent on Monday]
                    </Typography>
                )}
                </Grid>
            </Grid>
        </>
    )
}

WeeklyReportEnabled.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func
};

export default WeeklyReportEnabled;
