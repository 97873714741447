import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios';
import routes from 'routes';
import permissions from 'permissions';
import rules from 'rbac-rules';
import {check} from 'components/RBAC/AccessControl';
import {setBrands as setPropsBrands} from 'actions';

export default (params, dependencies, condition) => {
    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {role} = useSelector(state => state.users.currentUser);
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(routes.brands.endpoint, {params});
                setBrands(result.data.data);
                dispatch(setPropsBrands(result.data.data));
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        if (check(rules, role, [permissions.brands.list]) && condition) {
            setIsLoading(true);
            fetchData();
        }
    }, dependencies);

    return {isLoading, brands};
}
