import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import {KeyboardBackspace as KeyboardBackspaceIcon} from '@material-ui/icons';
import Brands from './Brands';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
        color: theme.palette.primary.dark
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    button: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
}));

function AgencyEditComponent(props) {

    const [agencyName, setAgencyName] = useState('');
    const [agencyActive, setAgencyActive] = useState(true);

    useEffect(() => {
        setAgencyName(props.agency.name);
        setAgencyActive(props.agency.active)
    }, [props.agency])
    
    const [error, setError] = useState(false);
    const history = useHistory();
    const classes = useStyles();

    const handleSubmit = (event) => {
        event.preventDefault();
        props.updateAgency({ name: agencyName, active: agencyActive });
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Card>
                        <CardHeader
                            avatar={
                                <IconButton aria-label='Go Back' edge='start' onClick={() => history.goBack()}>
                                    <KeyboardBackspaceIcon/>
                                </IconButton>
                            }
                            title='Edit Agency'
                            titleTypographyProps={
                                {variant: 'h5'}
                            }
                            subheaderTypographyProps={
                                {variant: 'body1'}
                            }
                            subheader='Edit any field and select update to modify the agency'
                        />
                        <Divider/>
                        <CardContent>
                            <form onSubmit={handleSubmit}>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={error}>
                                            <TextField
                                                id='agency-name'
                                                label='Agency Name'
                                                value={agencyName}
                                                onChange={event => setAgencyName(event.target.value)}
                                                size='small'
                                                required
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={error}>
                                            <InputLabel id='agency-status-label'>Status</InputLabel>
                                            <Select
                                                labelId='agency-status-label'
                                                id='agency-status'
                                                value={agencyActive}
                                                onChange={event => setAgencyActive(event.target.value === 'true')}
                                                input={<Input/>}
                                                renderValue={(value => value ? 'Active' : 'Inactive')}
                                            >
                                                <MenuItem value='true'>
                                                    Active
                                                </MenuItem>

                                                <MenuItem value='false'>
                                                    Inactive
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid container item justify="flex-end" xs={12}>
                                        <Button
                                            variant="text"
                                            className={classes.button}
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            type='submit'
                                            className={classes.button}
                                            disabled={agencyName == props.agency.agencyName && agencyActive == props.agency.agencyActive || !agencyName}
                                        >
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Brands brands={props.brands}/>
                </Grid>
            </Grid>
        </div>
    );
}

AgencyEditComponent.propTypes = {
    agency: PropTypes.object,
    updateAgency: PropTypes.func,
    brands: PropTypes.array,
};

export default AgencyEditComponent;
