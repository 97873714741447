import {Grid, Switch, Typography} from "@material-ui/core";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {check} from "../../RBAC/AccessControl";
import rules from "../../../rbac-rules";
import {useSelector} from "react-redux";
import permissions from "../../../permissions";

function BingEnabled(props) {


    const {brand, updateBrand, saveBrand} = props;
    const [bingEnabled, setBingEnabled] = useState(brand.bingEnabled);
    const {role} = useSelector(state => state.users.currentUser);

    const handleBingEnabledChange = (event) => {
        setBingEnabled(event.target.checked)
        const updatedBrand = {...brand, bingEnabled: event.target.checked};
        saveBrand({brand: updatedBrand, msg: 'Bing enabled updated!'});
        updateBrand(updatedBrand);
    };

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Enable Bing
            </Typography>
            <Grid component="label" container alignItems="center">
                <Grid item>Off</Grid>
                <Grid item>
                    <Switch
                        id="bingEnabled"
                        checked={bingEnabled}
                        onChange={event => handleBingEnabledChange(event)}
                        name="bingEnabled"
                        disabled={!check(rules, role, [permissions.brands.update.basic,] )}
                    />
                </Grid>
                <Grid item>On</Grid>
            </Grid>
        </>
    )
}

BingEnabled.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func
};

export default BingEnabled;
